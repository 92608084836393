import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
export interface TaskProfile {
  isSelected: boolean;
  id: string;
  attributes: {
    task_name: string;
    legel_service_type: any;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    id: string;
    account_id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    tasks: ITask[];
  };
}
export interface LegalServiceType {
  id: string,
  name: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start


  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskProfile:TaskProfile[],
  taskView:{
  ids: string;
  attributes: {
    task_name: string;
    legel_service_type: LegalServiceType;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    jurisdiction:string,
    deadline:string,
    description:string
  };
  },
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  mobileOpen: boolean;
  open: boolean;
  openDialog: boolean
  drawerOpen: boolean,
  taskViewId:string,
  
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.SessionResponseMessage),
  
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],

      isVisibleModal: false,
      dropdownTasks: false,
      mobileOpen: false,
      open: false,
      openDialog: false,
      drawerOpen: false,
      taskProfile:[],
      taskView:{
        ids:"",
        attributes: {
          task_name: "",
          legel_service_type: {
            id:"",
            name:""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:""
            }
        },
      taskViewId:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTaskLists();
  }

  getTaskLists = async() => {
    const ctoken: string = await getStorageData("clientTokes");

    const header = {
      "token": ctoken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    console.log("Headers sent:", header);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "projectTemplates");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getTaskListsApiCallId) {
      if (responseJson.data && responseJson.data.length > 0) {
        this.setState({ taskProfile: responseJson.data});
      } else {
        this.setState({ taskProfile: [], });
      }
    } 
    if (apiRequestCallId === this.getTasksApiCallId) {
      if (responseJson.data) {
        this.setState({ taskView: responseJson.data});
      } 
      
    } 
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  showAddModal = () => {
    this.setState({
      name: "",
      dropdownTasks: false,
      isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name });
  };

  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {
    this.setState({ open: true })

  };

  handleClose = () => {
    this.setState({ open: false });

  };

  toggleDrawer = (task:{id:string}) => {   
    const taskId = String(task.id); 
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen, 
      taskViewId: taskId, 
    }));
    this.getTasks()
  };

  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false }); 
  };



  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };
  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  handleEditSelect = (item: ITaskList) => {
    this.setState({
      id: item.id,
      name: item.attributes.name,
      selectedTasks: item.attributes.tasks,
      dropdownTasks: false,
    });
    this.showEditModal();
  };

  handleTasksSelect = (dataId: string) => {
    let newData = this.state.tasksData.map((task: ITask) => {
      if (task.id === dataId) {
        return { ...task, isSelected: !task.isSelected };
      }
      return task;
    });
    this.setState({ tasksData: newData });
  };

  // Function to fetch task list from the API
 

  // Function to add new task list and send it to API
  addTaskList = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to edit task list and send it to API
  editTaskList = (tasklistId: number) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete task list and send it to API
  deleteTaskList = (tasklistId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch tasks from the API
  getTasks = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const header = {
      "token": ctoken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
